@use 'vars' as *;
form {
  display: block;
}
input[type="text"], input[type="tel"],
input[type="email"], input[type="date"],
input[type="number"], 
textarea {
  position: relative;
  display: block;
  font-family: $font-family;
  margin-bottom: 10px;
  outline: none;
  box-shadow: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  resize: none;
  width: 100%;
  height: 100%;
  max-height: 40px;
  padding: 8px 15px;
  border: none;
  font-size: 16px;
  line-height: 20px;
  color: #161616;
  border: 1px solid #CACACA;
  background: #ffffff;
  border-radius: 5px;
  transition: all 0.15s ease;
  transition: all .2s ease-out;
  &:focus {
    border-color: var(--theme-color-primary);
  }
}
textarea {
  height: auto;
  max-height: 155px;
}
.is-invalid {
  display: block;
  position: relative;
  input {
    border-color: $red;
  }
}
[type="checkbox"] {
  display: none;
}
[type="checkbox"] ~ label {
  position: relative;
  display: block;
  margin-bottom: 12px;
  padding-left: 20px;
  @include font(12px, 15px);
  a {
    text-decoration: underline;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    border: 1px solid #BEBEBE;
    background-color: #fff;
  }
  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 1px;
    width: 12px;
    height: 12px;
  }
}
[type="checkbox"]:checked + label {
  &:after {
    background: url('../img/svg/checked.svg') no-repeat 0 0/contain;
  }
}


[type="radio"] {
  display: none;
}
[type="radio"] ~ label {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 24px;
  padding-left: 30px;
  @include font(12px, 15px);
  text-align: left;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #CACACA;
    background-color: #fff;
    border-radius: 50%;
  }
  &:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
  &:hover:before {
    border-color: var(--theme-color-primary);
  }
  @media (min-width: 992px) {
    @include font(16px);
  }
}
[type="radio"]:checked + label {
  &:after {
    background: var(--theme-color-primary);
  }
}

.form-control {
  margin-bottom: 4px;
  label {
    display: block;
    margin-bottom: 4px;
    @include font(13px, 16px);
  }
}
.chosen-container-single .chosen-single div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.chosen-container-single .chosen-single div b {
  width: 10px;
  height: 10px;
  background: url('../img/svg/chosen-arrow.svg') no-repeat 0 0;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background: url('../img/svg/chosen-arrow.svg') no-repeat 0 0;
  transform: rotate(180deg);
}
.chosen-container-single {
  display: block;
  width: 100% !important;
  margin-bottom: 10px;
}
.chosen-container-single .chosen-single {
  width: 100%;
  height: 100%;
  max-height: 40px;
  padding: 8px 15px;
  border: none;
  font-size: 16px;
  line-height: 20px;
  color: #161616;
  border: 1px solid #CACACA;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: none;
  transition: all 0.15s ease;
  transition: all .2s ease-out;
}
.is-invalid label:before {
  border-color: #f03333;
}
@use '../vars' as *;
.modal {
  @include center('both');
  position: fixed;
  z-index: 9200;
  width: 100%;
  display: none;
  max-width: 460px;
  padding: 40px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
  @media (min-width: 992px) {
    padding: 40px 60px;
  }
  .btn-close {
    padding: 27px 30px;
  }
  &-comment {
    margin-bottom: 0;
  }
  form {
    max-width: 400px;
    width: 100%;
    margin: 20px auto;
  }
  &-overlay {
    display: none;
    position: fixed;
    z-index: 9100;
    backdrop-filter: blur(3px);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
  }
  &-close {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 24px;
    height: 24px;
    transition: 0.3s all ease-out;
    &:hover {
      transform: rotate(90deg);
      &:after, &:before {
        background-color: #e54c3c;
      }
    }
  }
  &-close:after {
    height: 4px;
    width: 26px;
  }
  &-close:before {
    height: 26px;
    width: 4px;
  }
  &-close:after, &-close:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 5px;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    background-color: $modal-close;
    transition: all .1s ease-out;
  }
  &-title {
    @include font(24px, 1, 700);
  }
}
.modal-header {
  display: block;
  margin-bottom: 12px;
  text-align: center;
}
.modal-hint {
  display: block;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  @include font(18px, 23px);
  letter-spacing: 0.03em;
  color: $gray;
}
.modal-hint-code {
  display: block;
  text-align: center;
  @include font(24px, 1.3);
  letter-spacing: 0.03em;
  color: $gray;
}
.modal-thanks img {
  margin: 20px auto;
}

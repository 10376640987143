@use './vars' as *;

.features {
  padding: 30px 0;
  &-list {
    padding: 15px 0 0;
  }
  &-item {
    text-align: center;
  }
  &-image {
    display: flex;
    justify-content: center;
  }
  &-title {
    margin: 20px 0 10px;
    @include font(20px, 24px, 700);
  }
  @media (min-width: 992px) {
    padding: 50px 0 80px;
  }
}
@use './vars' as *;
:root {
  --theme-color-primary: #186416;
  --theme-color-secondary: #7BB51C;
  --main-bg-color: #FFFFFF;
  --text-color: #222222;
  --comment-color: #444444;
  --button-reset-color: #615A5A;
}
html {
  margin: 0;
  padding: 0;
}
body {
  position: relative;
  max-width: 1920px;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  @include font(16px, 20px);

  background: url('../img/bottom_water.png') no-repeat 50% calc(100% - 140px) / 100% auto;
  color: $black;
  font-family: $font-family;
  box-shadow: 0 0 10px #eeeeee;
  @media (min-width: 992px) {
    @include font(16px, 20px);
  }
  @media (min-width: 1366px) {
    background: url('../img/bubbler_l.png') no-repeat 0 1156px,
      url('../img/bubbles_r.png') no-repeat 100% 1156px,
      url('../img/bottom_water.jpg') no-repeat bottom center / 100% auto;
  }
  @media (max-width: 1900px) {
    max-width: 100%;
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  input, button {
    -webkit-appearance: none;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  a {
    text-decoration: none;
    color: var(--text-color);
    font-weight: normal;
    transition: all .2s ease-out;
    &:hover,
    &:focus {
      outline: none;
      color: var(--theme-color-secondary);
    }
  }
  p, li {
    font-weight: 300;
  }
}
.cancel-list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding-left: 0;
  }
  li:before {
    display: none;
  }
}
// ul {
//   padding: 0;
//   list-style: none;
//   li {
//     position: relative;
//     margin-bottom: 6px;
//     padding-left: 20px;
//     &:before {
//       content: '';
//       position: absolute;
//       top: 7px;
//       left: 0;
//       width: 4px;
//       height: 4px;
//       border-radius: 50%;
//       background: var(--theme-color-primary);
//     }
//   }
// }
h1 {
  margin-top: 0;
  margin-bottom: 13px;
  text-transform: uppercase;
  @include font(32px, 40px, 700);
  @media (min-width: 580px) {
    @include font(36px, 38px, 700);
  }
  @media (min-width: 992px) {
    @include font(44px, 54px, 700);
  }

}
h2 {
  margin: 0;
  margin-bottom: 15px;
  @include font(24px, 30px, 700);
  text-align: center;
  @media (min-width: 580px) {
    @include font(28px, 1.2, 700);
  }
  @media (min-width: 992px) {
    @include font(34px, 44px, 700);
  }
}
h3 {
  color: var(--text-color);
  @include font(16px, 20px, 600);
  @media (min-width: 992px) {
    @include font(18px, 24px, 600);
  }
}
.list-item {
  position: relative;
  padding-left: 20px;
  @include font(16px, 1.4, normal);
  color: $black;
  margin: 16px 0;
  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $red;
  }
}
.btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 160px;
  width: 100%;
  max-width: 380px;
  height: 60px;
  padding: 11px 14px;
  border: none;
  border-radius: 2px;
  text-align: center;
  font-family: $font-family;
  @include font(16px, 30px, 600);
  letter-spacing: 0.03em;
  transition: all 0.2s ease-out;
  color: $white;
  img:first-of-type {
    margin-right: 8px;
  }
  &:focus {
    outline: none;
  }
  &-default {
    position: relative;
    z-index: 1;
    background: var(--theme-color-secondary);
    &:hover {
      background: var(--theme-color-primary);
    }
  }
  &-secondary {
    background-color: #fff;
    color: var(--theme-color-secondary);
    border: 1px solid var(--theme-color-secondary);
    &:hover {
      background-color: #ECF4EB;
    }
    @media (min-width: 992px) {
      max-width: 280px;
    }
  }
  &-reset {
    position: relative;
    z-index: 1;
    color: var(--button-reset-color);
    background: #f0f0f0;
    font-weight: 500;
    img {
      margin-left: 10px;
    }
    &:hover {
      background: #d6d6d6;
    }
  }
}
@media (max-width: 991px) {
  button + button {
    margin-top: 10px;
  }
}
.close-cross {
  position: absolute;
  right: 15px;
  top: 15px;
  transition: all 400ms ease;
  &:hover {
    transform: rotate(180deg) scale(1.2);
  }
}
.progress {
  position: relative;
  padding-top: 10px;
  padding-bottom: 20px;
  &-track {
    height: 7px;
    background-color: var(--theme-color-secondary);
    border-radius: 10px;
  }
  &-bar {
    position: absolute; 
    top: 10px;
    left: 0;
    width: 0;
    height: 7px;
    background-color: var(--theme-color-primary);
    border-radius: 10px;
    transition: all 200ms ease-in-out;
  }
  @media (min-width: 992px) {
    display: none;
  }
}
.down-arrow {
  transition: all 200ms ease;
}
.no-scroll {
  overflow: hidden;
}

.table {
  padding: 30px 0 0;
  &-wrap {
    margin-right: -30px;
    overflow: auto;
    margin-bottom: 16px;
    @media (min-width: 768px) {
      margin-right: 0;
      overflow: hidden;
    }
  }
  &-row {
    display: grid;
    @include font(13px, 15px);
    border-bottom: 1px solid #e8e9ee;
  }
  &-header {
    @include font(12px, 14px);
    color: #ffffff;
    background: var(--theme-color-primary);
    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--theme-color-primary);
      text-align: center;
      @media (min-width: 992px) {
        padding: 12px 16px;
      }
    }
    .table-cell:last-of-type {
      justify-content: center;
    }
  }
  &-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    word-wrap: break-word;
    @media (min-width: 992px) {
      padding: 10px 8px;
    }
  }
  &-cell:not(:first-child) {
    border-left: 1px solid #e8e9ee;
  }
  &-input input[type="text"]{
    max-height: 35px;
    margin: 0;
    line-height: 16px;
    text-align: center;
  }
  &-cell:last-of-type {
    justify-content: flex-end;
  }
  &-radio:not(:first-of-type) {
    margin-top: 8px;
  }
  &-footer {
    &-row {
      display: grid;
      grid-template-columns: 330px 200px 90px;
      @media (min-width: 992px) {
        grid-template-columns: 390px 200px 92px;
      }
    }
    &-row:nth-child(odd) .table-cell {
      background-color: #ECF4EB;
      font-weight: 700;
    }
    &-cell {
      justify-content: flex-start;
      @include font(13px, 15px, 500);
    }
    &-cell:not(:first-child) {
      border: none;
    }
    
    
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
@use '../vars' as *;

.footer {
  padding: 80px 0 30px;
  color: var(--text-color);
  @include font(14px, 30px, 400);
  @media (min-width: 992px) {
    padding-top: 300px;
    padding-bottom: 0;
  }
  a {
    color: var(--theme-color-primary);
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 580px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
@use 'vars' as *;
.container {
  padding: 0 30px;
  @media(min-width: 1200px) {
    width: 100%;
    max-width: 1240px;
    margin: auto;
    flex-shrink: 0;
  }
}

$red: #f00000;
$green: #186416;
$button-color: #7BB51C;
$comment-color: #444444;
$gray: #F0F0F0;
$black: #000000;
$orange: #FBBE20;
$white: #ffffff;
$blue: #6699ff;
$text-color: #222222;
$hover-color: #ffffff;
$header-color: #55dd55;
$modal-close: #332c2c;
$font-family: 'Montserrat', sans-serif;

@mixin flex($dir: row, $wrap: nowrap, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-flow: $dir $wrap;
  justify-content: $justify;
  align-items: $align;
}

@mixin font($font-size: 15px, $line-height: 1.37, $font-weight: normal) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
}

@mixin center ($position) {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  @else if $position == 'horizontal' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@mixin abs-position ($top, $right, $bottom, $left) {
   position: absolute;
   top: $top;
   right: $right;
   bottom: $bottom;
   left: $left;
}

@mixin aspect-ratio ($width, $height) {
   position: relative;
   &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ($height / $width) * 100%;
   }
   > .inner-box {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
   }
}

@use './vars' as *;

.hero {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  background-image: url('../img/hero_bg_m.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  color: #fff;
  @media (min-width: 768px) {
    padding-top: 180px;
    padding-bottom: 150px;
    background-image: url('../img/hero_bg.jpg');
  }
  &-wrap {
    position: relative;
  }
  h1 {
    color: #ffffff;
    margin: 0;
  }
  &-comment {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 3px;
    @media (min-width: 768px) {
      font-size: 36px;
      line-height: 42px;
    }
  }
  &-todo {
    max-width: 550px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 20px;
    @media (min-width: 768px) {
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 30px;
    }
  }
  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 380px;
    margin-bottom: 16px;
    img {
      max-width: 197px;
    }
    @media (min-width: 992px) {
      position: absolute;
      top: -50px;
      right: 0;
      img {
        max-width: none;
      }
    }
    @media (min-width: 992px) {
      right: auto;
      left: 600px;
    }
  }
}
.fast-forward {
  display: none;
  margin-left: 20px;
  @media (min-width: 1200px) {
    display: block;
  }
}
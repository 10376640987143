@use './vars' as *;
.selection {
  &-comment {
    max-width: 944px;
    margin-left: auto;
    margin-right: auto;
    @include font(16px, 24px);
    color: var(--comment-color);
    text-align: center;
  }
  &-table {
    max-width: 680px;
    margin: 0 auto;
    padding: 30px 0 30px;
    &-row {
      display: grid;
      grid-template-columns: 50% 50%;
      @include font(13px, 15px);
      border-bottom: 1px solid #e8e9ee;
      @media (min-width: 992px) {
        grid-template-columns: 55% 45%;
      }
    }
    &-header {
      @include font(12px, 14px);
      color: #ffffff;
      background: var(--theme-color-primary);
      .selection-table-cell {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-cell {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 8px;
      word-wrap: break-word;
      @media (min-width: 992px) {
        padding: 12px 20px;
        @include font(16px);
      }
    }
    &-cell:not(:first-child) {
      text-align: center;
    }
    &-cell:nth-child(2) {
      border-left: 1px solid #e8e9ee;
    }
    &-input input[type="text"]{
      max-height: 35px;
      margin: 0;
      line-height: 16px;
      text-align: center;
    }
    &-radio:not(:first-of-type) {
      margin-top: 8px;
    }
  }
  &-radio-wrap {
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .selection-table-radio {
      @media (min-width: 992px) {
        margin: 0;
        margin-right: 16px;
      }
    }
  }
  &-buttons {
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 780px;
      margin: 0 auto;
    }
  }
}
@use './vars' as *;

.result {
  padding: 30px 0;
  @media (min-width: 992px) {
    padding: 50px 0;
  }
  &-image {
    max-width: 200px;
    margin: 15px auto 0;
    @media (min-width: 992px) {
      max-width: 400px;
    }
  }
  &-table {
    margin-right: -30px;
    padding: 0;
    padding-top: 20px;
    @media (min-width: 992px) {
      padding-top: 0;
    }
    &-row {
      width: 620px;
      grid-template-columns: 90px 240px 100px 100px 90px;
      text-align: center;
      font-weight: 500;
      @media (min-width: 992px) {
      grid-template-columns: 90px 300px 100px 100px 90px;
      width: 682px;
      }
    }
    &-name {
      justify-content: flex-start;
      text-align: left;
    }
  }
  &-text {
    @include font(13px, 18px);
  }
  &-buttons {
    margin-top: 16px;
  }
  &-row {
    @media (min-width: 992px) {
      display: flex;
      padding-top: 15px;
      justify-content: space-between;
    }
  }
  &-column {
    max-width: 682px;
  }
  &-buttons {
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
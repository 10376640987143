@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
:root {
  --theme-color-primary: #186416;
  --theme-color-secondary: #7BB51C;
  --main-bg-color: #FFFFFF;
  --text-color: #222222;
  --comment-color: #444444;
  --button-reset-color: #615A5A;
}

html {
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  max-width: 1920px;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  background: url("../img/bottom_water.png") no-repeat 50% calc(100% - 140px)/100% auto;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 0 10px #eeeeee;
}
@media (min-width: 992px) {
  body {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
  }
}
@media (min-width: 1366px) {
  body {
    background: url("../img/bubbler_l.png") no-repeat 0 1156px, url("../img/bubbles_r.png") no-repeat 100% 1156px, url("../img/bottom_water.jpg") no-repeat bottom center/100% auto;
  }
}
@media (max-width: 1900px) {
  body {
    max-width: 100%;
  }
}
body *,
body *:before,
body *:after {
  box-sizing: border-box;
}
body input, body button {
  -webkit-appearance: none;
}
body img {
  display: block;
  max-width: 100%;
  height: auto;
}
body a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: normal;
  transition: all 0.2s ease-out;
}
body a:hover, body a:focus {
  outline: none;
  color: var(--theme-color-secondary);
}
body p, body li {
  font-weight: 300;
}

.cancel-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.cancel-list li {
  padding-left: 0;
}
.cancel-list li:before {
  display: none;
}

h1 {
  margin-top: 0;
  margin-bottom: 13px;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}
@media (min-width: 580px) {
  h1 {
    font-size: 36px;
    line-height: 38px;
    font-weight: 700;
  }
}
@media (min-width: 992px) {
  h1 {
    font-size: 44px;
    line-height: 54px;
    font-weight: 700;
  }
}

h2 {
  margin: 0;
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
}
@media (min-width: 580px) {
  h2 {
    font-size: 28px;
    line-height: 1.2;
    font-weight: 700;
  }
}
@media (min-width: 992px) {
  h2 {
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
  }
}

h3 {
  color: var(--text-color);
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
@media (min-width: 992px) {
  h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
}

.list-item {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: normal;
  color: #000000;
  margin: 16px 0;
}
.list-item:before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #f00000;
}

.btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 160px;
  width: 100%;
  max-width: 380px;
  height: 60px;
  padding: 11px 14px;
  border: none;
  border-radius: 2px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 0.03em;
  transition: all 0.2s ease-out;
  color: #ffffff;
}
.btn img:first-of-type {
  margin-right: 8px;
}
.btn:focus {
  outline: none;
}
.btn-default {
  position: relative;
  z-index: 1;
  background: var(--theme-color-secondary);
}
.btn-default:hover {
  background: var(--theme-color-primary);
}
.btn-secondary {
  background-color: #fff;
  color: var(--theme-color-secondary);
  border: 1px solid var(--theme-color-secondary);
}
.btn-secondary:hover {
  background-color: #ECF4EB;
}
@media (min-width: 992px) {
  .btn-secondary {
    max-width: 280px;
  }
}
.btn-reset {
  position: relative;
  z-index: 1;
  color: var(--button-reset-color);
  background: #f0f0f0;
  font-weight: 500;
}
.btn-reset img {
  margin-left: 10px;
}
.btn-reset:hover {
  background: #d6d6d6;
}

@media (max-width: 991px) {
  button + button {
    margin-top: 10px;
  }
}
.close-cross {
  position: absolute;
  right: 15px;
  top: 15px;
  transition: all 400ms ease;
}
.close-cross:hover {
  transform: rotate(180deg) scale(1.2);
}

.progress {
  position: relative;
  padding-top: 10px;
  padding-bottom: 20px;
}
.progress-track {
  height: 7px;
  background-color: var(--theme-color-secondary);
  border-radius: 10px;
}
.progress-bar {
  position: absolute;
  top: 10px;
  left: 0;
  width: 0;
  height: 7px;
  background-color: var(--theme-color-primary);
  border-radius: 10px;
  transition: all 200ms ease-in-out;
}
@media (min-width: 992px) {
  .progress {
    display: none;
  }
}

.down-arrow {
  transition: all 200ms ease;
}

.no-scroll {
  overflow: hidden;
}

.table {
  padding: 30px 0 0;
}
.table-wrap {
  margin-right: -30px;
  overflow: auto;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .table-wrap {
    margin-right: 0;
    overflow: hidden;
  }
}
.table-row {
  display: grid;
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
  border-bottom: 1px solid #e8e9ee;
}
.table-header {
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: #ffffff;
  background: var(--theme-color-primary);
}
.table-header .table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-color-primary);
  text-align: center;
}
@media (min-width: 992px) {
  .table-header .table-cell {
    padding: 12px 16px;
  }
}
.table-header .table-cell:last-of-type {
  justify-content: center;
}
.table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  word-wrap: break-word;
}
@media (min-width: 992px) {
  .table-cell {
    padding: 10px 8px;
  }
}
.table-cell:not(:first-child) {
  border-left: 1px solid #e8e9ee;
}
.table-input input[type=text] {
  max-height: 35px;
  margin: 0;
  line-height: 16px;
  text-align: center;
}
.table-cell:last-of-type {
  justify-content: flex-end;
}
.table-radio:not(:first-of-type) {
  margin-top: 8px;
}
.table-footer-row {
  display: grid;
  grid-template-columns: 330px 200px 90px;
}
@media (min-width: 992px) {
  .table-footer-row {
    grid-template-columns: 390px 200px 92px;
  }
}
.table-footer-row:nth-child(odd) .table-cell {
  background-color: #ECF4EB;
  font-weight: 700;
}
.table-footer-cell {
  justify-content: flex-start;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
}
.table-footer-cell:not(:first-child) {
  border: none;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.container {
  padding: 0 30px;
}
@media (min-width: 1200px) {
  .container {
    width: 100%;
    max-width: 1240px;
    margin: auto;
    flex-shrink: 0;
  }
}

form {
  display: block;
}

input[type=text], input[type=tel],
input[type=email], input[type=date],
input[type=number],
textarea {
  position: relative;
  display: block;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  resize: none;
  width: 100%;
  height: 100%;
  max-height: 40px;
  padding: 8px 15px;
  border: none;
  font-size: 16px;
  line-height: 20px;
  color: #161616;
  border: 1px solid #CACACA;
  background: #ffffff;
  border-radius: 5px;
  transition: all 0.15s ease;
  transition: all 0.2s ease-out;
}
input[type=text]:focus, input[type=tel]:focus,
input[type=email]:focus, input[type=date]:focus,
input[type=number]:focus,
textarea:focus {
  border-color: var(--theme-color-primary);
}

textarea {
  height: auto;
  max-height: 155px;
}

.is-invalid {
  display: block;
  position: relative;
}
.is-invalid input {
  border-color: #f00000;
}

[type=checkbox] {
  display: none;
}

[type=checkbox] ~ label {
  position: relative;
  display: block;
  margin-bottom: 12px;
  padding-left: 20px;
  font-size: 12px;
  line-height: 15px;
  font-weight: normal;
}
[type=checkbox] ~ label a {
  text-decoration: underline;
}
[type=checkbox] ~ label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #BEBEBE;
  background-color: #fff;
}
[type=checkbox] ~ label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 1px;
  width: 12px;
  height: 12px;
}

[type=checkbox]:checked + label:after {
  background: url("../img/svg/checked.svg") no-repeat 0 0/contain;
}

[type=radio] {
  display: none;
}

[type=radio] ~ label {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 24px;
  padding-left: 30px;
  font-size: 12px;
  line-height: 15px;
  font-weight: normal;
  text-align: left;
}
[type=radio] ~ label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #CACACA;
  background-color: #fff;
  border-radius: 50%;
}
[type=radio] ~ label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
[type=radio] ~ label:hover:before {
  border-color: var(--theme-color-primary);
}
@media (min-width: 992px) {
  [type=radio] ~ label {
    font-size: 16px;
    line-height: 1.37;
    font-weight: normal;
  }
}

[type=radio]:checked + label:after {
  background: var(--theme-color-primary);
}

.form-control {
  margin-bottom: 4px;
}
.form-control label {
  display: block;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
}

.chosen-container-single .chosen-single div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.chosen-container-single .chosen-single div b {
  width: 10px;
  height: 10px;
  background: url("../img/svg/chosen-arrow.svg") no-repeat 0 0;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background: url("../img/svg/chosen-arrow.svg") no-repeat 0 0;
  transform: rotate(180deg);
}

.chosen-container-single {
  display: block;
  width: 100% !important;
  margin-bottom: 10px;
}

.chosen-container-single .chosen-single {
  width: 100%;
  height: 100%;
  max-height: 40px;
  padding: 8px 15px;
  border: none;
  font-size: 16px;
  line-height: 20px;
  color: #161616;
  border: 1px solid #CACACA;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: none;
  transition: all 0.15s ease;
  transition: all 0.2s ease-out;
}

.is-invalid label:before {
  border-color: #f03333;
}

.header {
  position: relative;
  max-width: 100%;
}
.header-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.header .logo {
  order: 1;
  max-width: 120px;
}
@media (min-width: 992px) {
  .header .logo {
    max-width: none;
  }
}
.header .logo-text {
  display: block;
  max-width: 380px;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.8px;
  color: var(--theme-color-primary);
}
.header .phone {
  display: flex;
  align-items: center;
  order: 3;
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
  margin-top: 8px;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .header .phone {
    order: 2;
  }
}
.header .phone-link {
  display: inline-block;
  font-weight: bold;
}
.header .phone-text {
  display: inline-block;
  font-size: 18px;
  line-height: 26px;
  font-weight: normal;
  margin-left: 8px;
}
.header .phone img {
  margin-right: 8px;
}
.header .partner {
  max-width: 70px;
  order: 2;
}
@media (min-width: 992px) {
  .header .partner {
    max-width: none;
  }
}

.hero {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  background-image: url("../img/hero_bg_m.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  color: #fff;
}
@media (min-width: 768px) {
  .hero {
    padding-top: 180px;
    padding-bottom: 150px;
    background-image: url("../img/hero_bg.jpg");
  }
}
.hero-wrap {
  position: relative;
}
.hero h1 {
  color: #ffffff;
  margin: 0;
}
.hero-comment {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 3px;
}
@media (min-width: 768px) {
  .hero-comment {
    font-size: 36px;
    line-height: 42px;
  }
}
.hero-todo {
  max-width: 550px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .hero-todo {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 30px;
  }
}
.hero-image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 380px;
  margin-bottom: 16px;
}
.hero-image img {
  max-width: 197px;
}
@media (min-width: 992px) {
  .hero-image {
    position: absolute;
    top: -50px;
    right: 0;
  }
  .hero-image img {
    max-width: none;
  }
}
@media (min-width: 992px) {
  .hero-image {
    right: auto;
    left: 600px;
  }
}

.fast-forward {
  display: none;
  margin-left: 20px;
}
@media (min-width: 1200px) {
  .fast-forward {
    display: block;
  }
}

.features {
  padding: 30px 0;
}
.features-list {
  padding: 15px 0 0;
}
.features-item {
  text-align: center;
}
.features-image {
  display: flex;
  justify-content: center;
}
.features-title {
  margin: 20px 0 10px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
@media (min-width: 992px) {
  .features {
    padding: 50px 0 80px;
  }
}

.selection-comment {
  max-width: 944px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: var(--comment-color);
  text-align: center;
}
.selection-table {
  max-width: 680px;
  margin: 0 auto;
  padding: 30px 0 30px;
}
.selection-table-row {
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
  border-bottom: 1px solid #e8e9ee;
}
@media (min-width: 992px) {
  .selection-table-row {
    grid-template-columns: 55% 45%;
  }
}
.selection-table-header {
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: #ffffff;
  background: var(--theme-color-primary);
}
.selection-table-header .selection-table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selection-table-cell {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px;
  word-wrap: break-word;
}
@media (min-width: 992px) {
  .selection-table-cell {
    padding: 12px 20px;
    font-size: 16px;
    line-height: 1.37;
    font-weight: normal;
  }
}
.selection-table-cell:not(:first-child) {
  text-align: center;
}
.selection-table-cell:nth-child(2) {
  border-left: 1px solid #e8e9ee;
}
.selection-table-input input[type=text] {
  max-height: 35px;
  margin: 0;
  line-height: 16px;
  text-align: center;
}
.selection-table-radio:not(:first-of-type) {
  margin-top: 8px;
}
@media (min-width: 992px) {
  .selection-radio-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
@media (min-width: 992px) {
  .selection-radio-wrap .selection-table-radio {
    margin: 0;
    margin-right: 16px;
  }
}
@media (min-width: 992px) {
  .selection-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 780px;
    margin: 0 auto;
  }
}

.result {
  padding: 30px 0;
}
@media (min-width: 992px) {
  .result {
    padding: 50px 0;
  }
}
.result-image {
  max-width: 200px;
  margin: 15px auto 0;
}
@media (min-width: 992px) {
  .result-image {
    max-width: 400px;
  }
}
.result-table {
  margin-right: -30px;
  padding: 0;
  padding-top: 20px;
}
@media (min-width: 992px) {
  .result-table {
    padding-top: 0;
  }
}
.result-table-row {
  width: 620px;
  grid-template-columns: 90px 240px 100px 100px 90px;
  text-align: center;
  font-weight: 500;
}
@media (min-width: 992px) {
  .result-table-row {
    grid-template-columns: 90px 300px 100px 100px 90px;
    width: 682px;
  }
}
.result-table-name {
  justify-content: flex-start;
  text-align: left;
}
.result-text {
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
}
.result-buttons {
  margin-top: 16px;
}
@media (min-width: 992px) {
  .result-row {
    display: flex;
    padding-top: 15px;
    justify-content: space-between;
  }
}
.result-column {
  max-width: 682px;
}
@media (min-width: 992px) {
  .result-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer {
  padding: 80px 0 30px;
  color: var(--text-color);
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
}
@media (min-width: 992px) {
  .footer {
    padding-top: 300px;
    padding-bottom: 0;
  }
}
.footer a {
  color: var(--theme-color-primary);
}
.footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 580px) {
  .footer-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9200;
  width: 100%;
  display: none;
  max-width: 460px;
  padding: 40px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
}
@media (min-width: 992px) {
  .modal {
    padding: 40px 60px;
  }
}
.modal .btn-close {
  padding: 27px 30px;
}
.modal-comment {
  margin-bottom: 0;
}
.modal form {
  max-width: 400px;
  width: 100%;
  margin: 20px auto;
}
.modal-overlay {
  display: none;
  position: fixed;
  z-index: 9100;
  backdrop-filter: blur(3px);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
}
.modal-close {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 24px;
  height: 24px;
  transition: 0.3s all ease-out;
}
.modal-close:hover {
  transform: rotate(90deg);
}
.modal-close:hover:after, .modal-close:hover:before {
  background-color: #e54c3c;
}
.modal-close:after {
  height: 4px;
  width: 26px;
}
.modal-close:before {
  height: 26px;
  width: 4px;
}
.modal-close:after, .modal-close:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 5px;
  bottom: auto;
  right: auto;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  background-color: #332c2c;
  transition: all 0.1s ease-out;
}
.modal-title {
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
}

.modal-header {
  display: block;
  margin-bottom: 12px;
  text-align: center;
}

.modal-hint {
  display: block;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
  letter-spacing: 0.03em;
  color: #F0F0F0;
}

.modal-hint-code {
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 1.3;
  font-weight: normal;
  letter-spacing: 0.03em;
  color: #F0F0F0;
}

.modal-thanks img {
  margin: 20px auto;
}
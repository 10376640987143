@use '../vars' as *;

.header {
  position: relative;
  max-width: 100%;
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .logo {
    order: 1;
    max-width: 120px;
    @media (min-width: 992px) {
      max-width: none;
    }
  }
  .logo-text {
    display: block;
    max-width: 380px;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.8px;
    color: var(--theme-color-primary);
  }
  .phone {
    display: flex;
    align-items: center;
    order: 3;
    @include font(20px, 26px);
    margin-top: 8px;
    white-space: nowrap;
    @media (min-width: 992px) {
      order: 2;
    }
    &-link {
      display: inline-block;
      font-weight: bold;
    }
    &-text {
      display: inline-block;
      @include font(18px, 26px);
      margin-left: 8px;
    }
    img {
      margin-right: 8px;
    }
  }
  .partner {
    max-width: 70px;
    order: 2;
    @media (min-width: 992px) {
      max-width: none;
    }
  }

}